<template>
    <modal
        id="registration"
        :title="$t('modal.registration.heading')"
        type="general"
    >
        <form
            id="registration"
            @submit.prevent="submitRegister"
        >
            <div
                class="form-group w100"
                :class="{ error: errors.email }"
            >
                <label>{{ $t('form.fields.label.email_address') }}</label>
                <input
                    v-model="input.email"
                    type="text"
                    class="form-control"
                    name="email"
                >
                <span
                    v-if="errors.email"
                    class="error-message"
                >{{
                    errors.email
                }}</span>
            </div>
            <div
                class="form-group w100 has-icon"
                :class="{ error: errors.password }"
            >
                <label>{{ $t('form.fields.label.password') }}</label>
                <input
                    v-if="!showPassword"
                    v-model="input.password"
                    type="password"
                    class="form-control"
                    name="password"
                >
                <input
                    v-if="showPassword"
                    v-model="input.password"
                    type="text"
                    class="form-control"
                    name="password"
                >
                <img
                    v-if="!showPassword"
                    alt="show password icon"
                    src="/images/fe_eye.svg"
                    @click="showPassword = !showPassword"
                >
                <img
                    v-if="showPassword"
                    alt="gide password icon"
                    src="/images/fe_eye-off.svg"
                    @click="showPassword = !showPassword"
                >
                <span
                    v-if="errors.password"
                    class="error-message"
                >{{
                    errors.password
                }}</span>
            </div>
            <div
                class="form-group w100 has-icon"
                :class="{ error: errors.passwordConfirmation }"
            >
                <label>{{ $t('form.fields.label.password_confirmation') }}</label>
                <input
                    v-if="!showPasswordConfirmation"
                    v-model="input.password_confirmation"
                    type="password"
                    class="form-control"
                    name="password-confirmation"
                >
                <input
                    v-if="showPasswordConfirmation"
                    v-model="input.password_confirmation"
                    type="text"
                    class="form-control"
                    name="password-confirmation"
                >
                <img
                    v-if="!showPasswordConfirmation"
                    src="/images/fe_eye.svg"
                    alt="show password icon"
                    @click="showPasswordConfirmation = !showPasswordConfirmation"
                >
                <img
                    v-if="showPasswordConfirmation"
                    src="/images/fe_eye-off.svg"
                    alt="hide password icon"
                    @click="showPasswordConfirmation = !showPasswordConfirmation"
                >
                <span
                    v-if="errors.passwordConfirmation"
                    class="error-message"
                >{{
                    errors.passwordConfirmation
                }}</span>
            </div>
            <div
                class="form-group w100"
                :class="{ error: errors.terms }"
            >
                <label class="terms mt-3">
                    <div class="custom-checkbox">
                        <input
                            v-model="termsAccepted"
                            type="checkbox"
                            name="terms"
                        >
                        <div class="checkmark">
                            <check-icon />
                        </div>
                    </div>
                    <div class="text">
                        <p class="sm">{{ $t('modal.registration.termsLabel') }}</p>
                    </div>
                </label>
                <span
                    v-if="errors.terms"
                    class="error-message"
                >{{
                    errors.terms
                }}</span>
            </div>
            <b-button
                variant="primary"
                class="fill lg register"
                :disabled="sending"
                type="submit"
            >
                {{ $t('modal.registration.submit') }}
            </b-button>
        </form>
        <div class="separator d-flex">
            <hr>
            <p class="sm">
                {{ $t('modal.login.orText') }}
            </p>
            <hr>
        </div>
        <social-login />
        <div class="member d-flex">
            <p class="sm">
                {{ $t('modal.registration.contExistText') }}
            </p>
            <p
                class="login sm-sbold"
                @click="showLoginPopup()"
            >
                {{ $t('modal.login.heading') }}
            </p>
        </div>
    </modal>
</template>
<script>
    import CheckIcon from 'vue-feather-icons/icons/CheckIcon';
    import { mapActions, mapMutations, mapState } from 'vuex';

    import { formValidations } from '@/services/formValidations';
    import { inputErrors } from '@/services/inputErrors';

    import Modal from './Modal.vue';
    import SocialLogin from './SocialLogin';
    export default {
        name: 'RegistrationModal',
        components: {
            Modal,
            CheckIcon,
            SocialLogin,
        },
        data() {
            return {
                termsAccepted: false,
                showPassword: false,
                showPasswordConfirmation: false,
                input: {
                    email: '',
                    password: '',
                    password_confirmation: '',
                },
                sending: false,
                errors: {},
            };
        },
        computed: {
            ...mapState('auth', ['registered', 'inputErrors']),
        },
        methods: {
            ...mapMutations('auth', ['setRegistered']),
            ...mapMutations('general', ['setModal']),
            ...mapActions('auth', ['register']),
            async submitRegister() {
                this.errors = formValidations(this.input);
                if (!this.termsAccepted)
                    this.errors.terms = this.$t('form.validation.terms');
                if (Object.keys(this.errors).length !== 0) {
                    return;
                }
                this.sending = true;
                await this.register({
                    email: this.input.email,
                    password: this.input.password,
                    password_confirmation: this.input.password_confirmation,
                });
                this.sending = false;
                if (Object.keys(this.inputErrors).length > 0) {
                    this.errors = Object.assign({}, inputErrors(this.inputErrors));
                    return;
                }
                this.closeModal();
            },
            closeModal() {
                this.setModal({
                    show: false,
                    type: 'registration',
                });
                this.disabled = false;
            },
            reset() {
                this.setRegistered();
                this.input.email = '';
                this.input.password = '';
                this.input.password_confirmation = '';
            },
            showReSend() {
                this.setModal({
                    show: true,
                    type: 'reSend',
                });
            },
            showLoginPopup() {
                this.setModal({
                    show: false,
                    type: 'registration',
                });
                setTimeout(() => {
                    this.setModal({
                        show: true,
                        type: 'login',
                    });
                }, 200);
            },
        },
        watch: {
            isLogged() {
                this.closeModal();
            },
            registered: {
                handler(registered) {
                    if (registered) {
                        this.closeModal();
                        this.$toast.open({
                            message: this.$t('notification.verificationEmailSent'),
                            duration: 10000,
                            position: 'bottom-right',
                            type: 'success',
                        });
                        this.reset();
                        setTimeout(() => {
                            this.setModal({
                                show: true,
                                type: 'registrationSuccess',
                            });
                        }, 200);
                    }
                },
            },
        },
    };
</script>
